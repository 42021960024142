.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  -webkit-box-shadow: 0 3px 13px rgba(0,0,0,0.08);
          box-shadow: 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
          box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid rgba(72,72,72,0.2);
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid rgba(72,72,72,0.2);
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72,72,72,0.2);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #1bbc9b;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72,72,72,0.2);
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #1bbc9b;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #1bbc9b;
  color: #fff;
  fill: #fff;
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: #fff;
  fill: #fff;
}
.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #bbb;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72,72,72,0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}
.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72,72,72,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72,72,72,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(255,255,255,0.5);
}
.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255,255,255,0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-weekdays {
  background: #1bbc9b;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
      align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #1bbc9b;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  width: 307.875px;
  border-left: 1px solid rgba(72,72,72,0.2);
  border-right: 1px solid rgba(72,72,72,0.2);
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 rgba(72,72,72,0.2);
          box-shadow: -1px 0 0 rgba(72,72,72,0.2);
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
      -webkit-flex-basis: 14.2857143%;
          flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2;
}
.flatpickr-day.today {
  border-color: #bbb;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #1bbc9b;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border-color: #1bbc9b;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #1bbc9b;
          box-shadow: -10px 0 0 #1bbc9b;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
          box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72,72,72,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(72,72,72,0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #1bbc9b, 5px 0 0 #1bbc9b;
          box-shadow: -5px 0 0 #1bbc9b, 5px 0 0 #1bbc9b;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid rgba(72,72,72,0.2);
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72,72,72,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid rgba(72,72,72,0.2);
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #efefef;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}
.ReactVirtualized__Table__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -ms-flex: 0 0 24px;
      flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}
.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
}
.rst__row > * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #bbb 1px;
  border-left: none;
  -webkit-box-shadow: 0 2px 2px -2px;
          box-shadow: 0 2px 2px -2px;
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  display: -ms-flexbox;
  display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  -webkit-box-shadow: 0 2px 2px -2px;
          box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: auto;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 1px #000;
          box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
          box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: auto;
  left: initial;
}

.rst__placeholder {
  position: relative;
  height: 68px;
  max-width: 300px;
  padding: 10px;
}
.rst__placeholder,
.rst__placeholder > * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.rst__placeholder::before {
  border: 3px dashed #d9d9d9;
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}
.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
  opacity: 0 !important;
}
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: lightblue;
  border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
  overflow: auto !important;
}
.rst__virtualScrollOverride * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.rst__absoluteLineBlock {
  position: absolute;
  top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
  left: 0;
  right: auto;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
  right: 50%;
  left: auto;
  left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.rst__lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.rst__lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__highlightLineVertical {
  z-index: 3;
}
.rst__highlightLineVertical::before {
  position: absolute;
  content: '';
  background-color: #36c2f6;
  width: 8px;
  margin-left: -4px;
  left: 50%;
  top: 0;
  height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
  margin-left: 0;
  margin-left: initial;
  margin-right: -4px;
  left: auto;
  left: initial;
  right: 50%;
}

@-webkit-keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    -webkit-transform: translate(0, 300%);
            transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(0, 700%);
            transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 1000%);
            transform: translate(0, 1000%);
    opacity: 0;
  }
}

@keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    -webkit-transform: translate(0, 300%);
            transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(0, 700%);
            transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 1000%);
            transform: translate(0, 1000%);
    opacity: 0;
  }
}
.rst__highlightLineVertical::after {
  content: '';
  position: absolute;
  height: 0;
  margin-left: -4px;
  left: 50%;
  top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
  -webkit-animation: arrow-pulse 1s infinite linear both;
          animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
  margin-left: 0;
  margin-left: initial;
  margin-right: -4px;
  right: 50%;
  left: auto;
  left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
  z-index: 3;
  content: '';
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 0;
  right: auto;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
  z-index: 3;
}
.rst__highlightBottomLeftCorner::before {
  content: '';
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% + 4px);
  top: 0;
  right: 12px;
  width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 12px;
  right: auto;
  right: initial;
}

.rst__highlightBottomLeftCorner::after {
  content: '';
  position: absolute;
  height: 0;
  right: 0;
  top: 100%;
  margin-top: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
  left: 0;
  right: auto;
  right: initial;
  border-right: 12px solid #36c2f6;
  border-left: none;
}

